import _ from 'lodash'
import {
  User,
  ClientImportSearchResult,
  Client,
  BillingPlan,
  Invoice,
  Rep,
  LineItem,
} from '~/Models'
import { ApiBase } from '~/Kit'

class Api extends ApiBase {
  async onNeedsAuthentication({ ioc: { routes }, history }) {
    const {
      location: { pathname, search },
    } = window
    history.replace(routes.login({ r: `${pathname}${search}` }))
  }

  async machineGetClient(clientId, context) {
    const data = await this.get(
      this.route('api.machine.clients.get', { clientId }),
      context,
    )
    return new Client(data)
  }

  async machineGetInvoice(invoiceId, context) {
    const data = await this.get(
      this.route('api.machine.invoices.get', { invoiceId }),
      context,
    )
    return new Invoice(data)
  }

  async getCurrentUser(context) {
    const data = await this.get(route('api.user'), context)
    const user = new User(data)
    return user
  }

  async getClients(context) {
    const data = await this.get(this.route('api.clients'), context)
    const clients = _.sortBy(Client.create(data), (o) => o.name)
    return clients
  }

  async getClient(clientId, context) {
    const data = await this.get(
      this.route('api.clients.get', { clientId }),
      context,
    )
    const client = new Client(data)
    return client
  }

  async searchDivisionsByName(name, context) {
    const data = await this.get(
      this.route('api.clients.import.search', {
        name,
      }),
      context,
    )
    const results = ClientImportSearchResult.create(data)
    return results
  }

  async importClient(divisionId, context) {
    const data = await this.get(
      this.route('api.clients.import.importByDivisionId', { divisionId }),
      context,
    )
    return new Client(data)
  }

  async getBillingPlans(context) {
    const data = await this.get(this.route('api.billingPlans'), context)
    return BillingPlan.create(data)
  }

  async saveClient(clientId, client, context) {
    const data = await this.post(
      this.route('api.clients.save', { clientId }),
      {
        client,
      },
      context,
    )
    return new Client(data)
  }

  async getInvoices(clientId, context) {
    const data = await this.get(
      this.route('api.clients.invoices.list', { clientId }),
      context,
    )
    return Invoice.create(data)
  }

  async getInvoice(clientId, invoiceId, context) {
    const data = await this.get(
      this.route('api.clients.invoices.get', { clientId, invoiceId }),
      context,
    )
    return new Invoice(data)
  }

  async deleteInvoice(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.delete', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      {},
      context,
    )
    return Invoice.create(data)
  }

  async copyInvoice(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.copy', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      {},
      context,
    )
    return Invoice.create(data)
  }

  async createInvoiceDraft(clientId, context) {
    const data = await this.get(
      this.route('api.clients.invoices.new', {
        clientId,
      }),
      context,
    )
    return new Invoice(data)
  }

  async updateInvoice(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.update', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { invoice },
      context,
    )
    return new Invoice(data)
  }

  async addInvoiceLineItem(client, invoice, lineItem, context) {
    const data = await this.post(
      this.route('api.clients.invoices.add_custom_line_item', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { lineItem },
      context,
    )
    return new Invoice(data)
  }

  async addPostpayLineItems(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.add_postpay_line_items', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { invoice },
      context,
    )
    return new Invoice(data)
  }

  async updatePostpayLineItems(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.update_postpay_line_items', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { invoice },
      context,
    )
    return new Invoice(data)
  }

  async deleteLineItem(client, invoice, item, context) {
    const data = await this.post(
      this.route('api.clients.invoices.delete_line_item', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { item },
      context,
    )
    return new Invoice(data)
  }

  async deleteAllLineItems(client, invoice, item, context) {
    const data = await this.post(
      this.route('api.clients.invoices.delete_all_line_items', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { item },
      context,
    )
    return new Invoice(data)
  }

  async finalizeInvoice(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.finalize', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { invoice },
      context,
    )
    return new Invoice(data)
  }

  async generateInvoicePdf(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.generate_pdf', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { invoice },
      context,
    )
    return new Invoice(data)
  }

  async sendInvoiceEmail(client, invoice, context) {
    const data = await this.post(
      this.route('api.clients.invoices.send_email', {
        clientId: client.id,
        invoiceId: invoice.id,
      }),
      { invoice },
      context,
    )
    return new Invoice(data)
  }

  async createClientInvoicePreviewFromForm(
    clientId,
    startDate,
    endDate,
    form,
    context,
  ) {
    const data = await this.post(
      this.route('api.clients.invoices.preview', {
        clientId,
        startDate,
        endDate,
      }),
      { form },
      context,
    )
    return new LineItem(data)
  }

  async addPaymentToInvoice(clientId, invoiceId, form, context) {
    const data = await this.post(
      this.route('api.clients.invoices.add_payment', {
        clientId,
        invoiceId,
      }),
      {
        payment: form,
      },
      context,
    )
    return new Invoice(data)
  }

  async login(email, password, context) {
    const data = await this.post(
      route('api.login'),
      {
        email,
        password,
      },
      context,
    )
    return new User(data)
  }

  async logout(context) {
    await this.post(route('api.logout'), context)
    return null
  }

  async getReceivablesReport(startDate, endDate, repFilterId, context) {
    const data = await this.get(
      this.route('api.reporting.receivables', {
        startDate,
        endDate,
        repFilterId,
      }),
      context,
    )
    return LineItem.create(data)
  }

  async getProfitReport(startDate, endDate, repFilterId, context) {
    const data = await this.get(
      this.route('api.reporting.profit', {
        startDate,
        endDate,
        repFilterId,
      }),
      context,
    )
    return LineItem.create(data)
  }

  async getCostReport(startDate, endDate, repFilterId, context) {
    const data = await this.get(
      this.route('api.reporting.cost', {
        startDate,
        endDate,
        repFilterId,
      }),
      context,
    )
    return LineItem.create(data)
  }

  async getPaymentsReport(startDate, endDate, repFilterId, context) {
    const data = await this.get(
      this.route('api.reporting.payments', {
        startDate,
        endDate,
        repFilterId,
      }),
      context,
    )
    return LineItem.create(data)
  }

  async getDelinquenciesReport(context) {
    const data = await this.get(
      this.route('api.reporting.delinquencies'),
      context,
    )
    return LineItem.create(data)
  }

  async getReps(context) {
    const data = await this.get(this.route('api.reps'), context)
    const clients = _.sortBy(Rep.create(data), (o) => o.name)
    return clients
  }
}

export { Api }
