import React from 'react'
import _ from 'lodash'
import { subscribe } from 'react-contextual'
import { ComponentBase, TabView } from '~/Kit'
import * as Form from './tabs'

@subscribe('ioc')
class Profile extends ComponentBase {
  renderLoaded() {
    const { route, client, reps, onSubmit } = this.props
    return (
      <TabView
        route={route}
        client={client}
        reps={reps}
        onSubmit={onSubmit}
        buttonProps={{ basic: true, size: 'mini' }}
      />
    )
  }
}

const GeneralTab = (props) => {
  return <Form.General {...props} />
}

const PreferencesTab = (props) => {
  return <Form.Preferences {...props} />
}

Profile.routes = {
  general: {
    component: GeneralTab,
    menu: true,
    default: true,
  },
  preferences: {
    component: PreferencesTab,
    menu: true,
  },
}

export { Profile }
