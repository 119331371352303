import React from 'react'
import _ from 'lodash'
import {
  Container,
  Icon,
  List,
  Breadcrumb,
  Label,
  Button,
  Grid,
  Dropdown,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { subscribe } from 'react-contextual'
import { ComponentBase } from '~/Kit'

@subscribe('ioc')
class Lister extends ComponentBase {
  loadState() {
    return {
      clients: this.api.getClients(),
      billingPlans: this.api.getBillingPlans(),
      planFilter: [],
    }
  }

  onPlanFilterChange = (e, d) => {
    this.setState({ planFilter: d.value })
  }

  renderLoaded({ clients, billingPlans }) {
    const { route } = this.props
    const { planFilter } = this.state
    const options = _.map(billingPlans, (p) => {
      return { key: p.slug, text: p.name, value: p.slug }
    })

    return (
      <Container>
        <div>
          <Breadcrumb>
            <Breadcrumb.Section active>Clients</Breadcrumb.Section>
          </Breadcrumb>
        </div>

        <Dropdown
          style={{ margin: 10 }}
          placeholder="Plans"
          fluid
          multiple
          selection
          options={options}
          onChange={this.onPlanFilterChange}
        />

        <List divided verticalAlign="middle">
          {_.map(clients, (c) => {
            if (
              planFilter.length > 0 &&
              _.indexOf(planFilter, c.billingPlan.slug) === -1
            )
              return null
            return (
              <List.Item key={c.id}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={1}>
                      <Icon name="user" disabled={!c.isActive} />
                    </Grid.Column>
                    <Grid.Column width={11}>
                      <List.Content>
                        <List.Header>
                          {c.name} ({c.slug})
                        </List.Header>
                        <List.Description>
                          <Label danger="true" size="mini">
                            <Icon name="dollar sign" /> {c.billingPlan.name}
                          </Label>
                          {c.alertsActive && (
                            <Icon
                              size="small"
                              name="announcement"
                              color="orange"
                              style={{ margin: 2 }}
                            />
                          )}
                        </List.Description>
                      </List.Content>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Button
                        primary
                        size="tiny"
                        as={Link}
                        to={route.parent.edit.profile({ client: c })}
                      >
                        Profile
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      {_.includes(['postpay', 'bulk'], c.billingPlan.slug) && (
                        <Button
                          primary
                          size="tiny"
                          as={Link}
                          to={route.parent.edit.billing.invoicer({
                            client: c,
                          })}
                        >
                          Invoicing
                        </Button>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Item>
            )
          })}
        </List>
      </Container>
    )
  }
}

export { Lister }
