import React from 'react'
import _ from 'lodash'
import { subscribe } from 'react-contextual'
import { ComponentBase, TabView } from '~/Kit'
import * as Form from './tabs'

@subscribe('ioc')
class Main extends ComponentBase {
  renderLoaded() {
    const { route, client, onSubmit } = this.props
    return (
      <TabView
        route={route}
        client={client}
        onSubmit={onSubmit}
        buttonProps={{ basic: true, size: 'mini' }}
      />
    )
  }
}

Main.routes = {
  plan: {
    component: Form.Billing,
    menu: true,
    default: true,
  },
  stripe: {
    component: Form.Stripe,
    menu: true,
  },
  invoicer: {
    component: Form.Invoicer,
    menu: {
      title: 'Invoicing',
    },
  },
}

export { Main }
