import { text } from './text'

type Args = { value: string }

function emails(config = {}) {
  return text({
    defaultValue: (v) =>
      v
        .split(/,/)
        .map((s) => s.trim())
        .filter((s) => !!s)
        .join(', '),
    validate: (args: Args) => {
      const { value } = args
      if (!value) return true
      const emails = value
        .split(/,/)
        .map((s) => s.trim())
        .filter((s) => !!s)
      for (let i = 0; i < emails.length; i++) {
        const email = emails[i]
        const isValid =
          email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          ) !== null
        if (!isValid) return `${email} does not appear to be an email address.`
      }
      return true
    },
    icon: 'at',
    type: 'Emails',
    ...config,
  })
}

export { emails }
