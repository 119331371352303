import React, { Component } from 'react'
import _ from 'lodash'
import { Lister } from './Lister'
import { View } from './View'
import { RouteContainer } from '~/Kit'

class Main extends RouteContainer {}

Main.routes = {
  list: {
    default: true,
    component: Lister,
  },
  view: {
    component: View,
    path: ':invoice',
  },
}

export { Main }
