import React, { Component } from 'react'
import _ from 'lodash'
import {
  Input,
  Button,
  Container,
  Grid,
  Icon,
  Message,
  Header,
} from 'semantic-ui-react'
import { ComponentBase } from '~/Kit'
import { subscribe } from 'react-contextual'

@subscribe('ioc')
class Main extends ComponentBase {
  loadState() {
    return { value: '' }
  }

  handleSearchChange = (e, { value }) => {
    if (value.length < 1) return this.reset()
    this.setState({ value })
    if (value.length < 3) return null
    this.search(value)
    return null
  }

  search = _.debounce((value) => {
    this.setState({ search: this.api.searchDivisionsByName(value) })
  }, 500)

  handleImport = (id) => {
    this.setState({ [id]: this.api.importClient(id) })
  }

  renderHeader() {
    const { value } = this.state
    const search = this.asyncState('search')
    return (
      <Header block>
        Search{' '}
        <Input
          placeholder="Client name..."
          onChange={this.handleSearchChange}
          value={value}
          loading={search.isLoading}
          size="small"
          style={{ marginLeft: 10 }}
        />
        {search.error && <Message error>{search.error.message}</Message>}
      </Header>
    )
  }

  renderLoaded() {
    const search = this.asyncState('search')
    return (
      <Container>
        <div>
          <Grid style={{ margin: 20 }}>
            {search.isLoaded &&
              search.response.length > 0 &&
              _.map(search.response, (r) => {
                const { isLoading, isLoaded, response } = this.asyncState(r.id)
                return (
                  <Grid.Row key={r.id}>
                    <Grid.Column width={10}>{r.title}</Grid.Column>
                    <Grid.Column width={1}>
                      {!r.client_id && !isLoaded && (
                        <Button
                          icon
                          labelPosition="left"
                          primary
                          loading={isLoading}
                          onClick={() => this.handleImport(r.id)}
                        >
                          Import
                          <Icon name="cloud download" />
                        </Button>
                      )}
                      {(r.client_id || response) && (
                        <Button disabled icon labelPosition="left">
                          Imported
                          <Icon name="check" />
                        </Button>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                )
              })}
          </Grid>
        </div>
      </Container>
    )
  }
}

export { Main }
