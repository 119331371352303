import React, { Component } from 'react'
import _ from 'lodash'
import { Form as FormBase } from '~/Kit'

const { toggle } = FormBase.Builders

const Preferences = (props) => {
  const { client } = props
  const fields = {
    sendPaymentReceipts: toggle({
      defaultValue: client.sendPaymentReceipts,
    }),
    alertsActive: toggle({
      defaultValue: client.alertsActive,
    }),
  }

  return <FormBase {...props} fields={fields} />
}

export { Preferences }
