import React from 'react'
import { Container } from 'semantic-ui-react'
import { Receivables } from './Receivables'
import { Profit } from './Profit'
import { Cost } from './Cost'
import { Payments } from './Payments'
import { Delinquencies } from './Delinquencies'
import { TabView } from '~/Kit'

const Reporting = (props) => {
  const { route, ...rest } = props
  return (
    <Container>
      <TabView {...rest} route={route} />
    </Container>
  )
}

Reporting.routes = {
  receivables: {
    component: Receivables,
    menu: true,
    default: true,
  },
  delinquencies: {
    component: Delinquencies,
    menu: true,
  },
  payments: {
    component: Payments,
    menu: true,
  },
  profit: {
    component: Profit,
    menu: true,
    default: true,
  },
  cost: {
    component: Cost,
    menu: true,
    default: true,
  },
}

export { Reporting }
