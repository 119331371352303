import React from 'react'
import _ from 'lodash'
import { Table } from 'semantic-ui-react'
import { subscribe } from 'react-contextual'
import changeCase from 'change-case'
import { Link } from 'react-router-dom'
import { ComponentBase } from '~/Kit'
import { Currency } from '~/components/Currency'

const LineItem = (props) => {
  const { item, routes, keys } = props
  const cols = []
  _.each(keys, (k) => {
    if (k === 'client') {
      cols.push(
        <Table.Cell key={k}>
          <Link
            to={routes.clients.edit.billing.invoicer({
              client: item.client_id,
            })}
          >
            {item.client}
          </Link>
        </Table.Cell>,
      )
      return
    }
    cols.push(
      <Table.Cell key={k}>
        <Currency value={item[k]} />
      </Table.Cell>,
    )
  })
  return <Table.Row>{cols}</Table.Row>
}

const Header = (props) => {
  const { sums, keys } = props
  return (
    <React.Fragment>
      <Table.Row>
        {_.map(keys, (k) => (
          <Table.HeaderCell key={k}>{changeCase.title(k)}</Table.HeaderCell>
        ))}
      </Table.Row>
      <Table.Row>
        {_.map(keys, (k) => {
          const v = sums[k]
          return (
            <Table.HeaderCell key={k}>
              {_.isNumber(v) && <Currency value={v} />}
            </Table.HeaderCell>
          )
        })}
      </Table.Row>
    </React.Fragment>
  )
}

const Report = (props) => {
  const keys = ['client', 'current', '30', '60', '90+', 'total']
  const { report, routes } = props
  if (report.length === 0) return 'No delinquencies found.'
  const sums = { client: '' }
  _.each(keys, (k) => {
    if (k === 'client') return
    sums[k] = 0
  })
  _.each(report, (item) =>
    _.each(item, (v, k) => {
      if (k === 'client') return
      sums[k] += v
    }),
  )
  return (
    <div style={{ overflow: 'auto' }}>
      <Table celled>
        <Table.Header>
          <Header sums={sums} keys={keys} />
        </Table.Header>
        <Table.Body>
          {_.map(report, (item, idx) => {
            return (
              <LineItem key={idx} keys={keys} item={item} routes={routes} />
            )
          })}
        </Table.Body>
        <Table.Footer>
          <Header sums={sums} keys={keys} />
        </Table.Footer>
      </Table>
    </div>
  )
}

@subscribe('ioc')
class Delinquencies extends ComponentBase {
  loadState() {
    return {
      report: this.api.getDelinquenciesReport(),
    }
  }

  renderLoaded({ report }) {
    const { routes } = this.props.ioc
    return <Report report={report} routes={routes} />
  }
}

export { Delinquencies }
