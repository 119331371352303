import React from 'react'
import _ from 'lodash'
import { Table, Button, Icon, Message } from 'semantic-ui-react'
import { subscribe } from 'react-contextual'
import moment from 'moment'
import { ComponentBase, NoPrint, Async } from '~/Kit'
import { Invoice } from '~/components/Invoice/Invoice'

const DATE_FORMAT = 'M/D/YY'

@subscribe('ioc')
class View extends ComponentBase {
  loadState() {
    const { client, match } = this.props
    return {
      invoice: this.api.getInvoice(client.id, match.params.invoice),
      invoiceSent: false,
      pdfGenerated: false,
    }
  }

  handlePaymentAdded = (newInvoice) => {
    const { invoice } = this.state
    console.log('new invoice', { newInvoice })
    this.setState({ invoice: { ...invoice, response: newInvoice } })
  }

  handleGeneratePdf = () => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({ invoice: this.api.generateInvoicePdf(client, invoice) })
  }

  handleSendEmail = () => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({
      invoice: this.api.sendInvoiceEmail(client, invoice),
    })
  }

  handleDateChange = (e, { name, value, shouldSave = true }) => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    if (name === 'endDate') {
      invoice[name] = moment
        .tz(value, DATE_FORMAT, 'America/Boise')
        .endOf('day')
    } else {
      invoice[name] = moment
        .tz(value, DATE_FORMAT, 'America/Boise')
        .startOf('day')
    }
    if (shouldSave) {
      this.setState({ invoice: this.api.updateInvoice(client, invoice) })
    } else {
      this.setState({
        invoice: { ...this.asyncState('invoice'), response: invoice },
      })
    }
  }

  handleAddCustomLineItem = (form) => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({
      invoice: this.api.addInvoiceLineItem(client, invoice, form),
      showCustomLineItemForm: false,
      showBulkPayLineItemForm: false,
    })
  }

  handleInsertPostpayItems = async () => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({ invoice: this.api.addPostpayLineItems(client, invoice) })
  }

  handleUpdatePostpayItems = async () => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({ invoice: this.api.updatePostpayLineItems(client, invoice) })
  }

  handleDeleteLineItem = async (item) => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({ invoice: this.api.deleteLineItem(client, invoice, item) })
  }

  handleFinalizeInvoice = async () => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({
      invoice: this.api.finalizeInvoice(client, invoice),
    })
  }

  handleDeleteAllLineItems = () => {
    const { client } = this.props
    const invoice = this.asyncState('invoice').response
    this.setState({
      invoice: this.api.deleteAllLineItems(client, invoice),
    })
  }

  renderLoaded({ invoice }) {
    const { generatingPdf, sendingEmail, invoiceSent } = this.state
    const { route, client } = this.props

    const audits = _.reverse(_.sortBy(invoice.audits, (a) => a.valueOf()))
    const pdfIsLoading = generatingPdf && generatingPdf.isLoading
    const latestPdf = _.find(audits, { type: 'pdf' })
    const latestPdfSucceeded = latestPdf ? latestPdf.status === 'ok' : null
    const emailIsLoading = sendingEmail && sendingEmail.isLoading
    const latestEmail = _.find(audits, { type: 'mail' })
    const latestEmailSucceeded = latestEmail
      ? latestEmail.status === 'ok'
      : null
    return (
      <React.Fragment>
        <NoPrint>
          <h1>
            {invoice.no}{' '}
            {invoice.status === 'finalized' && (
              <div style={{ float: 'right' }}>
                <Button
                  size="mini"
                  compact
                  href={window.route('api.clients.invoices.pdf', {
                    clientId: client.id,
                    invoiceId: invoice.id,
                  })}
                >
                  <Icon name="file pdf" />
                  Download PDF
                </Button>
              </div>
            )}
          </h1>
          {invoice.status === 'finalized' && (
            <React.Fragment>
              <Button
                icon
                loading={pdfIsLoading}
                disabled={pdfIsLoading}
                onClick={this.handleGeneratePdf}
              >
                {!pdfIsLoading && latestPdfSucceeded === null && (
                  <Icon name="file pdf" />
                )}
                {!pdfIsLoading && latestPdfSucceeded && (
                  <Icon name="check" color="green" />
                )}
                {!pdfIsLoading && latestPdfSucceeded === false && (
                  <Icon name="delete" color="red" />
                )}
                Generate PDF
              </Button>
              <Button
                icon
                loading={sendingEmail && sendingEmail.isLoading}
                disabled={sendingEmail && sendingEmail.isLoading}
                onClick={this.handleSendEmail}
              >
                {!emailIsLoading && latestEmailSucceeded === null && (
                  <Icon name="mail" />
                )}
                {!emailIsLoading && latestEmailSucceeded && (
                  <Icon name="check" color="green" />
                )}
                {!emailIsLoading && latestEmailSucceeded === false && (
                  <Icon name="delete" color="red" />
                )}
                Send Email
              </Button>
            </React.Fragment>
          )}
          {invoice.hasError && (
            <Message error>This invoice has errors. See history below.</Message>
          )}
          {!invoice.hasMetrics && (
            <Message warning>
              Warning: this client has no usage metrics for the specified
              period. Postpay line items cannot be added or updated.
            </Message>
          )}
        </NoPrint>
        <Invoice
          client={client}
          invoice={invoice}
          onDateChange={this.handleDateChange}
          onInsertPostpayItems={this.handleInsertPostpayItems}
          onUpdatePostpayItems={this.handleUpdatePostpayItems}
          onDeleteLineItem={this.handleDeleteLineItem}
          onFinalizeInvoice={this.handleFinalizeInvoice}
          onAddCustomLineItem={this.handleAddCustomLineItem}
          onDeleteAllLineItems={this.handleDeleteAllLineItems}
          onPaymentAdded={this.handlePaymentAdded}
        />
        <NoPrint>
          <h3>History</h3>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Message</Table.HeaderCell>
                <Table.HeaderCell>Txn ID</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.map(audits, (a) => (
                <Table.Row
                  key={a.id}
                  style={{
                    backgroundColor: a.status !== 'ok' ? '#e0b4b4' : 'iniital',
                  }}
                >
                  <Table.Cell style={{ whiteSpace: 'nowrap' }}>
                    {a.createdAt.tz('America/Boise').format('M/D/YY @ h:mm A')}
                  </Table.Cell>
                  <Table.Cell>{a.type}</Table.Cell>
                  <Table.Cell
                    style={{ color: a.status !== 'ok' ? 'red' : 'iniital' }}
                  >
                    {a.status}
                  </Table.Cell>
                  <Table.Cell>{a.message}</Table.Cell>
                  <Table.Cell>
                    {a.txnId && (
                      <a
                        href={`https://dashboard.stripe.com/payments/${a.txnId}`}
                        target="_blank"
                      >
                        {a.txnId}
                      </a>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </NoPrint>
      </React.Fragment>
    )
  }
}

export { View }
