import { boot } from '~/Kit/bootstrap'
import { Root } from '~/Kit/Root'
import { services } from './services'
import * as Sentry from '@sentry/browser'

Sentry.init({
  dsn: process.env.SENTRY_DSN,
})
console.log(process.env.SENTRY_DSN)

boot(Root, services)
