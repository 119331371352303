import React, { Component } from 'react'
import numeral from 'numeral'

const Rate = (props) => {
  const { value } = props
  return numeral(value)
    .format('$0.000000')
    .replace(/(\.\d{2}\d*?)0+$/, '$1')
}

export { Rate }
