import React from 'react'
import _ from 'lodash'
import { ComponentBase } from '~/Kit'
import { subscribe } from 'react-contextual'
import { Invoice } from './Invoice'

@subscribe('ioc')
class InvoiceLoader extends ComponentBase {
  loadState() {
    const { client, invoice } = this.props.match.params
    return {
      client: this.api.machineGetClient(client),
      invoice: this.api.machineGetInvoice(invoice),
    }
  }

  renderLoaded({ invoice, client }) {
    const { additionalLineItems } = this.props

    return (
      <Invoice
        client={client}
        invoice={invoice}
        additionalLineItems={additionalLineItems}
      />
    )
  }
}

export { InvoiceLoader }
