import _ from 'lodash'
import { ModelBase } from '../Kit'
import { Email } from './Email'

class Notification extends ModelBase {
  static getMutators() {
    return _.extend(super.getMutators(), {
      email: (email) => new Email(email),
    })
  }
}

export { Notification }
