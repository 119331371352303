import React, { Component } from 'react'
import _ from 'lodash'
import { Table, Button, Icon, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { subscribe } from 'react-contextual'
import { ComponentBase, Async } from '~/Kit'
import { Currency } from '~/components/Currency'

class InvoiceSummary extends Component {
  render() {
    const { invoice, client, route, onDelete, onCopy } = this.props

    return (
      <Table.Row
        style={{ backgroundColor: invoice.hasError ? '#e0b4b4' : 'iniital' }}
      >
        <Table.Cell>#{_.padStart(invoice.no, 3, '0')}</Table.Cell>
        <Table.Cell>
          {invoice.startDate.tz('America/Boise').format('M/D/YY')} -{' '}
          {invoice.endDate.tz('America/Boise').format('M/D/YY')}
        </Table.Cell>
        <Table.Cell>
          {invoice.dueAt && invoice.dueAt.tz('America/Boise').format('M/D/YY')}
        </Table.Cell>
        <Table.Cell>
          <Currency value={invoice.total} />
        </Table.Cell>
        <Table.Cell>
          <Currency value={invoice.balanceDue} />
        </Table.Cell>
        <Table.Cell>
          {invoice.status === 'draft' && (
            <div style={{ color: 'orange', fontWeight: 'bold' }}>draft</div>
          )}
          {invoice.status === 'finalized' && (
            <React.Fragment>
              {invoice.hasError && (
                <div style={{ fontWeight: 'bold', color: 'red' }}>error</div>
              )}
              {!invoice.hasError && (
                <React.Fragment>
                  {invoice.balanceDue > 0 &&
                    invoice.balanceDue < invoice.total && (
                      <div style={{ fontWeight: 'bold', color: 'orange' }}>
                        in progress
                      </div>
                    )}
                  {invoice.balanceDue <= 0 && (
                    <div style={{ fontWeight: 'bold', color: 'green' }}>
                      paid
                    </div>
                  )}
                  {invoice.balanceDue > 0 &&
                    invoice.balanceDue === invoice.total && (
                      <div style={{ fontWeight: 'bold', color: 'red' }}>
                        unpaid
                      </div>
                    )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Table.Cell>
        <Table.Cell>
          <Button
            icon="copy"
            size="tiny"
            onClick={() => onCopy(invoice)}
            primary
          />

          {invoice.status === 'draft' && (
            <Button
              icon="edit"
              size="tiny"
              as={Link}
              to={route.parent.view({ client, invoice })}
              primary
            />
          )}
          {invoice.status === 'finalized' && (
            <Button
              icon="eye"
              size="tiny"
              as={Link}
              to={route.parent.view({ client, invoice })}
              primary
            />
          )}
          <Button
            icon="delete"
            size="tiny"
            onClick={() => onDelete(invoice)}
            color="red"
          />
        </Table.Cell>
      </Table.Row>
    )
  }
}

@subscribe('ioc')
class Lister extends ComponentBase {
  loadState() {
    return {
      invoices: this.api.getInvoices(this.props.match.params.client),
      creating: null,
    }
  }

  handleCreate = () => {
    const { client } = this.props
    this.setState({
      creating: this.api.createInvoiceDraft(client.id).then((invoice) => {
        const { history, route } = this.props
        history.push(route.parent.view({ client, invoice }))
        this.props.ioc.setGlobalMessage('Invoice created.')
      }),
    })
  }

  handleDelete = (invoice) => {
    const { client } = this.props
    if (!confirm('Are you sure you want to delete this invoice?')) return
    this.setState({
      invoices: this.api.deleteInvoice(client, invoice),
    })
  }

  handleCopy = (invoice) => {
    const { client } = this.props
    this.setState({
      invoices: this.api.copyInvoice(client, invoice),
    })
  }

  renderLoaded({ invoices }) {
    const { client, route } = this.props
    const hasErrors = _.reduce(invoices, (r, i) => r || i.hasError, false)
    return (
      <div>
        {hasErrors && (
          <Message error>
            Warning: one or more invoices contains problems.
          </Message>
        )}
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>#No</Table.HeaderCell>
              <Table.HeaderCell>Period</Table.HeaderCell>
              <Table.HeaderCell>Due</Table.HeaderCell>
              <Table.HeaderCell>Total</Table.HeaderCell>
              <Table.HeaderCell>Balance Due</Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(invoices, (invoice, i) => (
              <InvoiceSummary
                key={i}
                {...{ invoice, client, route }}
                onDelete={this.handleDelete}
                onCopy={this.handleCopy}
              />
            ))}
          </Table.Body>
        </Table>
        <Button onClick={this.handleCreate}>
          <Icon name="add" />
          Create Invoice
        </Button>
      </div>
    )
  }
}

export { Lister }
