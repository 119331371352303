import React from 'react'
import _ from 'lodash'
import changeCase from 'change-case'
import { Route, Login, Logout, ensureLoggedIn, ensureLoggedOut } from '~/Kit'
import { InvoiceLoader } from './components/Invoice'
import { Lister, Editor } from './components/Clients'
import { Import } from './components/Import'
import { Reporting } from './components/Reporting'

Route.middleware.push((k, routeInfo, parentRouteInfo) => {
  if (!routeInfo.menu) return
  if (routeInfo.menu === true) routeInfo.menu = {}
  _.defaults(routeInfo.menu, {
    title: changeCase.title(k),
    position: null,
    icon: null,
    decorators: [],
  })
})

const routes = Route({
  chromeless: {
    chromeless: true,
    routes: {
      client: {
        path: ':client',
        routes: {
          invoice: {
            path: ':invoice',
            component: InvoiceLoader,
          },
        },
      },
    },
  },
  home: {
    default: true,
  },
  clients: {
    menu: {
      decorators: [ensureLoggedIn()],
    },
    routes: {
      list: {
        default: true,
        component: Lister,
      },
      edit: {
        path: ':client',
        component: Editor,
      },
    },
    decorators: [ensureLoggedIn({ redirect: true })],
  },
  import: {
    component: Import,
    menu: {
      decorators: [ensureLoggedIn()],
    },
    decorators: [ensureLoggedIn({ redirect: true })],
  },
  reports: {
    component: Reporting,
    menu: {
      decorators: [ensureLoggedIn()],
    },
    decorators: [ensureLoggedIn({ redirect: true })],
  },
  login: {
    menu: {
      title: 'Log In',
      position: 'right',
      icon: 'sign in',
      decorators: [ensureLoggedOut()],
    },
    component: Login,
  },
  logout: {
    menu: {
      title: 'Log Out',
      position: 'right',
      icon: 'sign out',
      decorators: [ensureLoggedIn()],
    },
    component: Logout,
  },
})

export { routes }
