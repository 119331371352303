import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Table } from 'semantic-ui-react'
import { Sparklines, SparklinesLine } from 'react-sparklines'

const AllotmentHistory = (props) => {
  const { logs, ...rest } = props
  logs.sort((a, b) => a.createdAt.diff(b.createdAt))

  return (
    <React.Fragment>
      <Sparklines data={_.map(logs, (l) => l.usage)}>
        <SparklinesLine color="blue" />
      </Sparklines>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Usage</Table.HeaderCell>
            <Table.HeaderCell>Allotment</Table.HeaderCell>
            <Table.HeaderCell>Pct</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(logs.reverse(), (n, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{n.usage}</Table.Cell>
              <Table.Cell>{n.allotment}</Table.Cell>
              <Table.Cell>
                {Math.round((n.usage / n.allotment) * 100)}%
              </Table.Cell>
              <Table.Cell>{n.createdAt.fromNow()}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

export { AllotmentHistory }
