import _ from 'lodash'
import { guard } from '~/Kit'

const hasBillingPlan = (options) => {
  const config = _.defaults({}, options, {
    test: ({ client }) =>
      _.includes(['postpay', 'bulk'], client.billingPlan.slug),
  })
  return guard(config)
}

export { hasBillingPlan }
