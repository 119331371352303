import React from 'react'
import _ from 'lodash'
import { Container, Breadcrumb, Message } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { subscribe } from 'react-contextual'
import { ComponentBase, TabView, NoPrint } from '~/Kit'
import { NotificationHistory } from './tabs/NotificationHistory'
import { AllotmentHistory } from './tabs/AllotmentHistory'
import { Biller } from './tabs/Biller'
import { Profile } from './tabs/Profile'
import { hasBillingPlan, ifBulkPay } from './guards'

@subscribe('ioc')
class Editor extends ComponentBase {
  loadState() {
    return {
      client: this.api.getClient(this.props.match.params.client),
      reps: this.api.getReps(),
    }
  }

  handleSave = (input) => {
    return this.api
      .saveClient(this.state.client.response.id, input)
      .then((client) => {
        this.setAsyncState({ client })
      })
  }

  renderLoaded({ client, reps }) {
    const { route } = this.props
    return (
      <Container>
        <NoPrint>
          <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Section as={Link} to={route.parent.list()}>
              Clients
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>{client.name}</Breadcrumb.Section>
          </Breadcrumb>
          {!client.isActive && (
            <Message negative>
              <Message.Header>
                Warning: This client is not active
              </Message.Header>
            </Message>
          )}
        </NoPrint>
        <TabView
          route={route}
          routeParams={{ client }}
          client={client}
          reps={reps}
          onSubmit={this.handleSave}
        />
      </Container>
    )
  }
}

Editor.routes = {
  profile: {
    component: Profile,
    menu: {},
    tab: true,
  },
  billing: {
    component: Biller,
    menu: {
      decorators: [],
    },
  },
  notifications: {
    component: (props) => (
      <NotificationHistory
        notifications={props.client.notifications}
        {...props}
      />
    ),
    menu: {},
  },
  usage: {
    component: (props) => (
      <AllotmentHistory logs={props.client.allotmentHistory} {...props} />
    ),
    menu: {},
    middleware: [ifBulkPay()],
  },
}

export { Editor }
