import React, { Component } from 'react'
import _ from 'lodash'
import { DateInput as SuiDateInput } from 'semantic-ui-calendar-react'
import moment from 'moment'
import { fieldState } from './fieldState'
import { input } from './input'

const DATE_FORMAT = 'M/D/YY'

class DateInput extends Component {
  handleChange = (e, { name, value }) => {
    const { onChange, fieldInfo } = this.props
    const { unmask } = fieldInfo
    const currentValue = unmask({ value })
    console.log({ name, value, currentValue })
    const dateArgs = fieldState(this.props)

    onChange({ ...dateArgs, value: currentValue })
  }

  render() {
    const { value, onChange } = this.props
    return (
      <SuiDateInput
        dateFormat={DATE_FORMAT}
        value={moment.unix(value).format(DATE_FORMAT)}
        name="start"
        onChange={this.handleChange}
      />
    )
  }
}
const render = (props) => {
  return <DateInput {...props} />
}

function date(config = {}) {
  return input({
    type: 'Date',
    render,
    defaultValue: () => moment().unix(),
    unmask: ({ value }) => moment(value, DATE_FORMAT).unix(),
    ...config,
  })
}

export { date }
