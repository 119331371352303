import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { subscribe } from 'react-contextual'
import { Form as FormBase, ComponentBase } from '~/Kit'
import { LineItemRenderer } from '~/components/Invoice/LineItemRenderer'

const {
  toggle,
  section,
  dropdown,
  div,
  rate,
  currency,
  quantity,
  text,
} = FormBase.Builders

@subscribe('ioc')
class Billing extends ComponentBase {
  loadState() {
    return {
      billingPlans: this.api.getBillingPlans(),
    }
  }

  handleValid = _.debounce((form) => {
    const { client } = this.props
    this.setState({
      thisMonth: this.api.createClientInvoicePreviewFromForm(
        client.id,
        moment().startOf('month'),
        moment(),
        form,
      ),
      lastMonth: this.api.createClientInvoicePreviewFromForm(
        client.id,
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
        form,
      ),
    })
  }, 500)

  renderLoaded({ billingPlans }) {
    const {
      client: { billingPlan, poNumber, remittenceInstructions },
    } = this.props

    const billingOptions = []
    _.each(billingPlans, (p) =>
      billingOptions.push({
        key: p.slug,
        value: p.slug,
        text: p.name,
      }),
    )

    const fields = {
      plan: dropdown({
        options: billingOptions,
        defaultValue: billingPlan.slug,
        label: 'Billing Plan',
      }),
      autoBill: dropdown({
        options: [
          {
            key: 'none',
            value: 'none',
            text: 'Autobill is disabled',
          },
          {
            key: 'check',
            value: 'check',
            text: 'Autobill by Check',
          },
          {
            key: 'stripe',
            value: 'stripe',
            text: 'Autobill by Stripe',
          },
        ],
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.autoBill,
        help:
          'Automatic billing will be enabled or disabled according to this setting.',
      }),
      autoBillDay: dropdown({
        options: [...Array(28).keys()].map((k) => ({
          key: k + 1,
          value: k + 1,
          text: `${k + 1}`,
        })),
        displayIf: ({ form }) =>
          form.autoBill === 'check' || form.autoBill === 'stripe',
        defaultValue: billingPlan.autoBillDay,
        help:
          'Automatic billing will be attempted on this day of the month (1=1st, 2=2nd, etc).',
      }),
      remittenceInstructions: text({
        defaultValue:
          remittenceInstructions ||
          'If paying by check, please remit payment to the name and address shown at the top of this invoice.',
      }),

      bulkRate: rate({
        label: 'Rate',
        displayIf: ({ form }) => form.plan === 'bulk',
        defaultValue: billingPlan.costPerMessage.bulk,
      }),
      bulkSendAlerts: toggle({
        label: 'Send Allotment Alerts',
        displayIf: ({ form }) => form.plan === 'bulk',
        defaultValue: billingPlan.bulkSendAlerts === true,
      }),
      bulkWarning1Threshold: quantity({
        displayIf: ({ form }) => form.plan === 'bulk' && form.bulkSendAlerts,
        label: 'Allotment Alert #1',
        defaultValue:
          billingPlan.bulkWarning1Threshold ||
          Math.trunc(billingPlan.allotment * 0.25),
        help: 'Send a warning when this many messages are remaining',
        min: 1,
        max: 100000,
      }),
      bulkWarning2Threshold: quantity({
        displayIf: ({ form }) => form.plan === 'bulk' && form.bulkSendAlerts,
        label: 'Allotment Alert #2',
        defaultValue:
          billingPlan.bulkWarning2Threshold ||
          Math.trunc(billingPlan.allotment * 0.1),
        help: 'Send a warning when this many messages are remaining',
        min: 1,
        max: ({ form }) => form.bulkWarning1Threshold - 1,
      }),
      bulkUsage: div({
        label: 'Usage',
        displayIf: ({ form }) => form.plan === 'bulk',
        content: (
          <div>
            {billingPlan.used}/{billingPlan.allotment}
          </div>
        ),
      }),
      poNumber: text({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: poNumber,
      }),
      includeCarrierSurcharges: toggle({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.includeCarrierSurcharges,
      }),
      includeInternationalCharges: toggle({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.includeInternationalCharges,
      }),
      outboundRate: rate({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.costPerMessage.outbound,
      }),
      inboundRate: rate({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.costPerMessage.inbound,
      }),
      lookupRate: rate({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.lookupRate,
      }),
      keywordNonAlphaRate: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.keywordNonAlphaRate,
        help: 'Price per keyword',
      }),
      keywordNonAlphaCredit: quantity({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.keywordNonAlphaCredit,
        help: 'The number of complimentary keywords (no charge)',
      }),
      keywordAlphaRate: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.keywordAlphaRate,
        help: 'Price per keyword',
      }),
      keywordAlphaCredit: quantity({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.keywordAlphaCredit,
        help: 'The number of complimentary keywords (no charge)',
      }),
      keywordMcardRate: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.keywordMcardRate,
        help: 'Price per keyword',
      }),
      keywordMcardCredit: quantity({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.keywordMcardCredit,
        help: 'The number of complimentary keywords (no charge)',
      }),
      longCodeDomesticRate: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.longCodeDomesticRate,
        help: 'Price per code',
      }),
      longCodeCanadaRate: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.longCodeCanadaRate,
        help: 'Price per code',
      }),
      longCodeInternationalRate: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.longCodeInternationalRate,
        help: 'Price per code',
      }),
      shortCodeTotalMonthlyLeaseFee: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.shortCodeTotalMonthlyLeaseFee,
        help: 'The monthly lease fee for all short codes (total)',
      }),
      shortCodeTotalMonthlyLeaseFeeCost: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.shortCodeTotalMonthlyLeaseFeeCost,
        help: 'The internal monthly lease cost for all short codes (total)',
      }),
      shortCodeTotalMonthlyHostingFee: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.shortCodeTotalMonthlyHostingFee,
        help: 'The monthly hosting fee for all short codes (total)',
      }),
      shortCodeTotalMonthlyHostingFeeCost: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.shortCodeTotalMonthlyHostingFeeCost,
        help: 'The internal monthly hosting cost for all short codes (total)',
      }),
      customFeeDescription: text({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.customFeeDescription,
        help: 'Custom monthly fee not covered above',
      }),
      customFeeQty: quantity({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.customFeeQty,
        help: 'Custom monthly fee not covered above',
      }),
      customFeeRate: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.customFeeRate,
        help: 'Custom monthly fee not covered above',
      }),
      customFeeCost: currency({
        displayIf: ({ form }) => form.plan === 'postpay',
        defaultValue: billingPlan.customFeeCost,
        help: 'Total internal monthly cost of custom fees',
      }),
      invoicingSection: section({
        displayIf: ({ form }) => form.plan === 'postpay',
        label: 'Invoice Simulator',
      }),
      thisMonth: div({
        displayIf: ({ form }) => form.plan === 'postpay',

        label: 'This Month',
        content: () => {
          const { isLoaded, response } = this.asyncState('thisMonth')
          if (!isLoaded) return null
          return <LineItemRenderer items={response} showZeroTotalLineItems />
        },
      }),
      lastMonth: div({
        displayIf: ({ form }) => form.plan === 'postpay',

        label: 'Last Month',
        content: () => {
          const { isLoaded, response } = this.asyncState('lastMonth')
          if (!isLoaded) return null
          return <LineItemRenderer items={response} showZeroTotalLineItems />
        },
      }),
    }

    const props = { ...this.props, fields, billingOptions }

    return <FormBase {...props} onValid={this.handleValid} />
  }
}

export { Billing }
