import _ from 'lodash'
import { guard } from '~/Kit'

const ifPostPay = (options) => {
  const config = _.defaults({}, options, {
    test: ({ client }) => {
      return client.billingPlan.slug === 'postpay'
    },
  })
  return guard(config)
}

export { ifPostPay }
