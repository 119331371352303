import React, { Component } from 'react'
import _ from 'lodash'
import { Table } from 'semantic-ui-react'
import { Currency, Rate, Number } from '~/components'
import moment from 'moment'

const DATE_FORMAT = 'M/D/YY'

class LineItemRenderer extends Component {
  static defaultProps = {
    showZeroTotalLineItems: false,
    additionalLineItems: () => null,
  }

  render() {
    const { items, showZeroTotalLineItems, additionalLineItems } = this.props
    const precision = 10 ** 6
    let total = 0
    _.each(items, (item) => {
      item.each = Math.trunc(item.each * precision) / precision
      item.total = Math.trunc(item.total * precision) / precision
      total += item.total
    })
    total = Math.trunc(total * precision) / precision

    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Item</Table.HeaderCell>
            <Table.HeaderCell>Unit Cost</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Charge</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(
            _.filter(items, (item, k) => {
              return item.each !== 0 || showZeroTotalLineItems
            }),
            (item, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>
                  <div>{item.memo}</div>
                  {item.internalMemo && <div>{item.internalMemo}</div>}
                  {item.total < 0 && (
                    <div>
                      posted{' '}
                      {(item.effectiveAt || moment())
                        .tz('America/Boise')
                        .format(DATE_FORMAT)}
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Currency value={item.each} />
                </Table.Cell>
                <Table.Cell>
                  <Number value={item.qty} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={item.total} />
                </Table.Cell>
              </Table.Row>
            ),
          )}
          <Table.Row>
            <Table.Cell colSpan={3}>BALANCE DUE</Table.Cell>
            <Table.Cell style={{ fontWeight: 'bold' }}>
              <Currency value={total} />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    )
  }
}
export { LineItemRenderer }
