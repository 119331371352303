import React, { Component } from 'react'
import numeral from 'numeral'

const Currency = (props) => {
  const { value } = props
  let s = `$${numeral(value).format('0,0.00')}`
  if (value < 0) s = `(${s})`
  return <span style={{ color: value >= 0 ? 'initial' : 'red' }}>{s}</span>
}

export { Currency }
