import React, { Component } from 'react'
import _, { map, reduce } from 'lodash'
import { Form as FormBase } from '~/Kit'

const { emails, phone, checklist, text } = FormBase.Builders

const General = (props) => {
  const { client, reps } = props
  const fields = {
    name: text({
      required: true,
      defaultValue: client.name,
    }),
    slug: text({
      required: true,
      validate: /^[a-z0-9]{1,4}$/i,
      defaultValue: client.slug,
      filter: ({ value }) =>
        value.toUpperCase().trim().replace(/\W/g, '').slice(0, 4),
    }),
    email: emails({
      defaultValue: client.email,
      label: 'Emails',
      help: 'Comma-separated list of email addresses',
    }),
    sms: phone({
      defaultValue: client.sms,
    }),
    city: text({
      defaultValue: client.city,
    }),
    state: text({
      defaultValue: client.state,
    }),
    zip: text({
      defaultValue: client.zip,
    }),
    reps: checklist({
      options: map(reps, (r) => ({ key: r.id, value: r.id, text: r.name })),
      defaultValue: reduce(
        client.reps,
        (carry, r) => {
          carry[r.id] = true
          return carry
        },
        {},
      ),
      label: 'Reps',
    }),
  }

  return <FormBase {...props} fields={fields} />
}

export { General }
