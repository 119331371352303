import _ from 'lodash'
import { guard } from '~/Kit'

const ifBulkPay = (options) => {
  const config = _.defaults({}, options, {
    test: ({ client }) => client.billingPlan.slug === 'bulk',
  })
  return guard(config)
}

export { ifBulkPay }
