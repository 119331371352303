import React from 'react'
import _ from 'lodash'
import { Dropdown, Grid, Table } from 'semantic-ui-react'
import { subscribe } from 'react-contextual'
import changeCase from 'change-case'
import { Link } from 'react-router-dom'

import { DatePicker, ComponentBase, Async, createDateRange } from '~/Kit'
import { Currency } from '~/components/Currency'

const ReceivablesReportLineItem = (props) => {
  const { item, routes } = props
  const cols = []
  _.each(item, (v, k) => {
    if (k === 'client_id') return
    if (k === 'client') {
      cols.push(
        <Table.Cell key={k}>
          <Link
            to={routes.clients.edit.billing.invoicer({
              client: item.client_id,
            })}
          >
            {v}
          </Link>
        </Table.Cell>,
      )
      return
    }
    if (k === 'reps') {
      cols.push(<Table.Cell key={k}>{v.join(', ')}</Table.Cell>)
      return
    }
    cols.push(
      <Table.Cell key={k}>
        <Currency value={v} />
      </Table.Cell>,
    )
  })
  return <Table.Row>{cols}</Table.Row>
}

const ReportHeader = (props) => {
  const { sums } = props
  const keys = _.keys(sums)
  const cols = []
  _.each(keys, (k) => {
    if (k === 'client_id') return
    cols.push(
      <Table.HeaderCell key={k}>{changeCase.title(k)}</Table.HeaderCell>,
    )
  })

  return (
    <React.Fragment>
      <Table.Row>{cols}</Table.Row>
      <Table.Row>
        {_.map(sums, (v, k) => (
          <Table.HeaderCell key={k}>
            {_.isNumber(v) && <Currency value={v} />}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </React.Fragment>
  )
}

const ReceivablesReport = (props) => {
  const { report, routes } = props
  if (report.length === 0) return 'No finalized invoices found for this period.'
  const sums = { client: '', reps: '' }
  _.each(report[0], (v, k) => {
    if (k === 'client_id') return
    if (k === 'client') return
    if (k === 'reps') return
    sums[k] = 0
  })
  _.each(report, (item) =>
    _.each(item, (v, k) => {
      if (k === 'client') return
      if (k === 'client_id') return
      if (k === 'reps') return
      sums[k] += v
    }),
  )
  return (
    <div style={{ overflow: 'auto' }}>
      <Table celled>
        <Table.Header>
          <ReportHeader sums={sums} />
        </Table.Header>
        <Table.Body>
          {_.map(report, (item, idx) => {
            return (
              <ReceivablesReportLineItem
                key={idx}
                item={item}
                routes={routes}
              />
            )
          })}
        </Table.Body>
        <Table.Footer>
          <ReportHeader sums={sums} />
        </Table.Footer>
      </Table>
    </div>
  )
}

@subscribe('ioc')
class Receivables extends ComponentBase {
  componentDidMount() {
    super.componentDidMount()
    const { start, end } = createDateRange('lastMonth')
    this.setState({
      report: this.api.getReceivablesReport(start, end, null),
      reps: this.api.getReps(),
      repFilterId: null,
      startDate: start,
      endDate: end,
    })
  }

  handleDateChange = (startDate, endDate) => {
    const { repFilterId } = this.state
    this.setState({
      startDate: startDate.startOf('day'),
      endDate: endDate.endOf('day'),
      report: this.api.getReceivablesReport(startDate, endDate, repFilterId),
    })
  }

  onRepChanged = (e, d) => {
    const { startDate, endDate } = this.state
    this.setState({
      repFilterId: d.value,
    })
    this.setState({
      report: this.api.getReceivablesReport(startDate, endDate, d.value),
    })
  }

  renderLoaded() {
    const { report, reps } = this.state
    const { routes } = this.props.ioc

    return (
      <Grid columns={1}>
        <Grid.Column>
          <DatePicker onChange={this.handleDateChange} />
          <Async
            watch={reps}
            onLoaded={(reps) => (
              <Dropdown
                placeholder="Rep Filter"
                fluid
                selection
                clearable="true"
                options={[
                  {
                    key: 'any',
                    text: 'All reps',
                    value: null,
                  },
                ].concat(
                  _.map(reps, (r) => ({
                    key: r.id,
                    text: r.name,
                    value: r.id,
                  })),
                )}
                onChange={this.onRepChanged}
              />
            )}
          />
        </Grid.Column>
        <Grid.Column>
          <Async
            watch={report}
            onLoaded={(report) => (
              <ReceivablesReport report={report} routes={routes} />
            )}
          />
        </Grid.Column>
      </Grid>
    )
  }
}

export { Receivables }
