import React, { Component } from 'react'
import _ from 'lodash'
import { Header, Table } from 'semantic-ui-react'

const NotificationHistory = (props) => {
  const { notifications, ...rest } = props
  return (
    <React.Fragment>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>To</Table.HeaderCell>
            <Table.HeaderCell>Sent</Table.HeaderCell>
            <Table.HeaderCell>Info</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {_.map(
            notifications.sort((a, b) => b.createdAt.diff(a.createdAt)),
            (n, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>{n.type}</Table.Cell>
                <Table.Cell>{n.email.to.email}</Table.Cell>
                <Table.Cell>{n.createdAt.fromNow()}</Table.Cell>
                <Table.Cell>
                  {Math.round((n.data.usage / n.data.allotment) * 100)}%
                </Table.Cell>
              </Table.Row>
            ),
          )}
        </Table.Body>
      </Table>
    </React.Fragment>
  )
}

export { NotificationHistory }
