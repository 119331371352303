import React, { Component } from 'react'
import _ from 'lodash'
import {
  Image,
  Header,
  Container,
  Icon,
  Button,
  Table,
} from 'semantic-ui-react'
import moment from 'moment'
import { ComponentBase, Form, NoPrintSpan } from '~/Kit'
import { subscribe } from 'react-contextual'
import { DateInput } from 'semantic-ui-calendar-react'
import { Currency, Number, Rate } from '~/components'
import { CreatePaymentLineItem } from './CreatePaymentLineItem'

const { text, quantity, currency, div, rate, dropdown } = Form.Builders

const DATE_FORMAT = 'M/D/YY'

const maybeCallOnRenderComplete = _.debounce(() => {
  console.log('rendering complete', window.onRenderComplete)
  if (window.onRenderComplete) window.onRenderComplete()
}, 2000)

class ToggleIcon extends Component {
  state = {
    isShowing: false,
  }

  handleToggle = () => {
    const { isShowing } = this.state
    console.log({ isShowing })
    this.setState({ isShowing: !isShowing })
  }

  render() {
    const { name, children } = this.props
    const { isShowing } = this.state
    return (
      <NoPrintSpan>
        <Icon name={name || 'pencil'} onClick={this.handleToggle} />
        {isShowing && children}
      </NoPrintSpan>
    )
  }
}

@subscribe('ioc')
class Invoice extends ComponentBase {
  loadState() {
    return {
      showCustomLineItemForm: false,
      showBulkPayLineItemForm: false,
    }
  }

  handleToggleCustomLineItemForm = () => {
    const { showCustomLineItemForm } = this.state
    this.setState({ showCustomLineItemForm: !showCustomLineItemForm })
  }

  handleToggleBulkPayLineItemForm = () => {
    const { showBulkPayLineItemForm } = this.state
    this.setState({ showBulkPayLineItemForm: !showBulkPayLineItemForm })
  }

  handleDeleteAll = () => {
    if (!confirm('Really delete all line items?')) return
    const { onDeleteAllLineItems } = this.props
    onDeleteAllLineItems()
  }

  createHandleDateChange = (shouldSave = true) => (e, { name, value }) => {
    console.log('sending', { name, value, shouldSave })
    this.props.onDateChange(e, { name, value, shouldSave })
  }

  renderLoaded() {
    const {
      showCustomLineItemForm,
      showBulkPayLineItemForm,
      addingPostpayLineItems,
      finalizingInvoice,
    } = this.state
    const {
      invoice,
      client,
      onDeleteLineItem,
      onInsertPostpayItems,
      onUpdatePostpayItems,
      onFinalizeInvoice,
      onAddCustomLineItem,
      onPaymentAdded,
    } = this.props
    const precision = 10 ** 6
    let total = 0
    _.each(invoice.lineItems, (item) => {
      item.each = Math.trunc(item.each * precision) / precision
      item.total = Math.trunc(item.total * precision) / precision
      total += item.total
    })
    total = Math.trunc(total * precision) / precision
    const isEditable = invoice.status === 'draft'
    const isFinalized = invoice.status === 'finalized'
    const yesterday = moment(new Date())
      .clone()
      .tz('America/Boise')
      .add(-1, 'days')
      .startOf('day')
      .format(DATE_FORMAT)
    const today = moment(new Date())
      .clone()
      .tz('America/Boise')
      .startOf('day')
      .format(DATE_FORMAT)

    const DI = ({ name, maxDate, minDate, shouldSave = true }) => (
      <DateInput
        inline
        minDate={minDate}
        maxDate={maxDate}
        name={name}
        dateFormat={DATE_FORMAT}
        value={invoice[name]
          .clone()
          .tz('America/Boise')
          .startOf('day')
          .format(DATE_FORMAT)}
        initialDate={invoice[name]
          .clone()
          .tz('America/Boise')
          .startOf('day')
          .format(DATE_FORMAT)}
        onChange={this.createHandleDateChange(shouldSave)}
      />
    )

    maybeCallOnRenderComplete()
    return (
      <Container>
        <Header as="h1" textAlign="center">
          Invoice
        </Header>
        <div style={{ height: 190 }}>
          <div style={{ float: 'left' }}>
            <div style={{ height: 120 }}>
              <Image src="/images/txtwire_logo_rgb.png" size="tiny" />
            </div>
            <div>
              <b>Bill to:</b>
              <br />
              {invoice.clientName}
              <br />
              {_.map(['city', 'state', 'zip', 'phone'], (k) => {
                if (!client[k]) return null
                return <div key={k}>{client[k]}</div>
              })}
            </div>
          </div>
          <div style={{ float: 'right' }}>
            <div style={{ height: 120 }}>
              Txtwire Technologies, Inc.
              <br />
              PO Box 50980
              <br />
              Idaho Falls, ID 83405
              <br />
              Phone: 208-227-7710
              <br />
              https://www.txtwire.com/
            </div>
            <div>
              <b>Invoice #:</b> {invoice.no}
              <br />
              <b>Invoice Date:</b>{' '}
              {invoice.statementAt &&
                invoice.statementAt
                  .clone()
                  .tz('America/Boise')
                  .format(DATE_FORMAT)}
              {isEditable && (
                <ToggleIcon>
                  <DI name="statementAt" maxDate={today} />
                </ToggleIcon>
              )}
              <br />
              <b>Due Date:</b>{' '}
              {invoice.dueAt &&
                invoice.dueAt.clone().tz('America/Boise').format(DATE_FORMAT)}
              {isEditable && (
                <ToggleIcon>
                  <DI name="dueAt" maxDate={today} />
                </ToggleIcon>
              )}
              <br />
              <b>Period:</b>{' '}
              <span>
                {invoice.startDate.clone().tz('America/Boise').format('M/D/YY')}
                -{invoice.endDate.clone().tz('America/Boise').format('M/D/YY')}
              </span>
              {isEditable && (
                <ToggleIcon>
                  <div>
                    <DI
                      name="startDate"
                      shouldSave={false}
                      maxDate={
                        invoice.billingPlanSlug === 'bulk' ? null : yesterday
                      }
                    />
                    <br />
                    <DI
                      name="endDate"
                      minDate={invoice.startDate
                        .clone()
                        .tz('America/Boise')
                        .format(DATE_FORMAT)}
                      maxDate={
                        invoice.billingPlanSlug === 'bulk' ? null : yesterday
                      }
                    />
                  </div>
                </ToggleIcon>
              )}
              <br />
              {invoice.poNumber && (
                <div>
                  <b>PO Number:</b> {invoice.poNumber}
                </div>
              )}
            </div>
          </div>
        </div>
        <Header as="h2">Billing Details</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Item</Table.HeaderCell>
              <Table.HeaderCell>Unit Cost</Table.HeaderCell>
              <Table.HeaderCell>Qty</Table.HeaderCell>
              <Table.HeaderCell>Charge</Table.HeaderCell>
              {isEditable && (
                <Table.HeaderCell className="no-print">
                  <Icon
                    color="red"
                    name="delete"
                    onClick={this.handleDeleteAll}
                  />{' '}
                  All
                </Table.HeaderCell>
              )}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {_.map(invoice.lineItems, (item, idx) => (
              <Table.Row key={idx}>
                <Table.Cell>
                  <div>{item.memo}</div>
                  {item.internalMemo && <div>{item.internalMemo}</div>}
                  {item.total < 0 && (
                    <div>
                      posted{' '}
                      {(item.effectiveAt || item.createdAt)
                        .clone()
                        .tz('America/Boise')
                        .format(DATE_FORMAT)}
                    </div>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Rate value={item.each} />
                </Table.Cell>
                <Table.Cell>
                  <Number value={item.qty} />
                </Table.Cell>
                <Table.Cell>
                  <Currency value={item.total} />
                </Table.Cell>
                {isEditable && (
                  <Table.Cell className="no-print">
                    <Icon
                      color="red"
                      name="delete"
                      onClick={() => onDeleteLineItem(item)}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
            {isEditable && (
              <React.Fragment>
                <Table.Row className="no-print">
                  <Table.Cell colSpan={5}>
                    <Button icon onClick={this.handleToggleCustomLineItemForm}>
                      <Icon name="plus" />
                      Custom Line Item
                    </Button>
                    {invoice.billingPlanSlug === 'bulk' && (
                      <Button
                        icon
                        onClick={this.handleToggleBulkPayLineItemForm}
                      >
                        <Icon name="plus" />
                        Bulk Pay Line Item
                      </Button>
                    )}
                    {invoice.billingPlanSlug === 'postpay' && (
                      <React.Fragment>
                        <Button
                          icon
                          loading={
                            addingPostpayLineItems &&
                            addingPostpayLineItems.isLoading
                          }
                          disabled={
                            (addingPostpayLineItems &&
                              addingPostpayLineItems.isLoading) ||
                            !invoice.hasMetrics
                          }
                          onClick={onInsertPostpayItems}
                        >
                          <Icon name="plus" />
                          Insert Postpay Line Items
                        </Button>
                        <Button
                          icon
                          loading={
                            addingPostpayLineItems &&
                            addingPostpayLineItems.isLoading
                          }
                          disabled={
                            (addingPostpayLineItems &&
                              addingPostpayLineItems.isLoading) ||
                            !invoice.hasMetrics
                          }
                          onClick={onUpdatePostpayItems}
                        >
                          <Icon name="plus" />
                          Update Postpay Line Items
                        </Button>
                      </React.Fragment>
                    )}
                    <Button
                      icon
                      color="green"
                      loading={finalizingInvoice && finalizingInvoice.isLoading}
                      disabled={
                        finalizingInvoice && finalizingInvoice.isLoading
                      }
                      onClick={onFinalizeInvoice}
                    >
                      <Icon name="check" />
                      Finalize
                    </Button>
                    {showCustomLineItemForm && (
                      <Form
                        fields={{
                          memo: text({
                            required: true,
                          }),
                          category: dropdown({
                            required: true,
                            defaultValue: 'general',
                            options: [
                              {
                                key: 'license-fee',
                                value: 'license-fee',
                                text: 'License fee',
                              },
                              {
                                key: 'outbound-messages',
                                value: 'outbound-messages',
                                text: 'Outbound messages',
                              },
                              {
                                key: 'short-code-lease',
                                value: 'short-code-lease',
                                text: 'Short code lease',
                              },
                              {
                                key: 'short-code-hosting',
                                value: 'short-code-hosting',
                                text: 'Short code hosting ',
                              },
                              {
                                key: 'long-code-lease',
                                value: 'long-code-lease',
                                text: 'Long code lease',
                              },
                              {
                                key: 'miscellaneous',
                                value: 'miscellaneous',
                                text: 'Miscellaneous',
                              },
                            ],
                          }),
                          qty: quantity({
                            label: 'Quantity',
                            required: true,
                            min: 1,
                            max: 999999,
                          }),
                          each: rate({
                            label: 'Each',
                            required: true,
                            min: 0.0,
                            max: 1000,
                          }),
                          total: div({
                            label: 'Total',
                            content: ({ form }) => (
                              <Rate value={form.qty * form.each} />
                            ),
                          }),
                        }}
                        onSubmit={onAddCustomLineItem}
                        onCancel={this.handleToggleCustomLineItemForm}
                        submitButtonText="Add Line Item"
                        submitButtonIcon="plus"
                        submittingMessage="Adding..."
                        submittedMessage="Added."
                        clearOnSubmitSuccess
                      />
                    )}
                    {showBulkPayLineItemForm && (
                      <Form
                        fields={{
                          memo: text({
                            required: true,
                            defaultValue: 'Block of Messages',
                          }),
                          category: dropdown({
                            required: true,
                            defaultValue: 'general',
                            options: [
                              {
                                key: 'license-fee',
                                value: 'license-fee',
                                text: 'License fee',
                              },
                              {
                                key: 'outbound-messages',
                                value: 'outbound-messages',
                                text: 'Outbound messages',
                              },
                              {
                                key: 'short-code-lease',
                                value: 'short-code-lease',
                                text: 'Short code lease',
                              },
                              {
                                key: 'short-code-hosting',
                                value: 'short-code-hosting',
                                text: 'Short code hosting ',
                              },
                              {
                                key: 'long-code-lease',
                                value: 'long-code-lease',
                                text: 'Long code lease',
                              },
                              {
                                key: 'miscellaneous',
                                value: 'miscellaneous',
                                text: 'Miscellaneous',
                              },
                            ],
                          }),
                          qty: quantity({
                            label: 'Quantity',
                            required: true,
                            defaultValue: 1000,
                            min: 100,
                            max: 999999,
                          }),
                          each: rate({
                            label: 'Each',
                            required: true,
                            min: 0.0,
                            defaultValue:
                              client.billingPlan.costPerMessage.bulk,
                          }),
                          total: div({
                            label: 'Total',
                            content: ({ form }) => (
                              <Currency value={form.qty * form.each} />
                            ),
                          }),
                        }}
                        onSubmit={onAddCustomLineItem}
                        onCancel={this.handleToggleBulkPayLineItemForm}
                        submitButtonText="Add Line Item"
                        submitButtonIcon="plus"
                        submittingMessage="Adding..."
                        submittedMessage="Added."
                        clearOnSubmitSuccess
                      />
                    )}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            )}
            {isFinalized && (
              <CreatePaymentLineItem
                onPaymentAdded={onPaymentAdded}
                invoice={invoice}
                client={client}
              />
            )}
            <Table.Row>
              <Table.Cell colSpan={3}>BALANCE DUE</Table.Cell>
              <Table.Cell style={{ fontWeight: 'bold' }}>
                <Currency value={total} />
              </Table.Cell>
              {isEditable && (
                <Table.Cell
                  className="no-print"
                  style={{ fontWeight: 'bold' }}
                />
              )}
            </Table.Row>
          </Table.Body>
        </Table>
        <p>{invoice.remittenceInstructions}</p>
      </Container>
    )
  }
}

export { Invoice }
