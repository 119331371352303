import { ModelBase } from '../Kit'
import { LineItem } from './LineItem'

class Invoice extends ModelBase {
  static getDates() {
    return super.getDates([
      'startDate',
      'endDate',
      'finalizedAt',
      'dueAt',
      'statementAt',
    ])
  }

  static getRelations() {
    return { lineItems: LineItem }
  }
}

export { Invoice }
