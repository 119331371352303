import React from 'react'
import { Table, Button, Icon } from 'semantic-ui-react'
import { subscribe } from 'react-contextual'
import _ from 'lodash'
import { ComponentBase, Async, Form } from '~/Kit'
import moment from 'moment'

const { text, currency, toggle, date } = Form.Builders

@subscribe('ioc')
class CreatePaymentLineItem extends ComponentBase {
  state = {
    isFormShowing: false,
    saving: Async.DEFAULT,
  }

  handleShowForm = () => {
    this.setState({ isFormShowing: true })
  }

  handleSubmit = (form) => {
    const { invoice, client, onPaymentAdded } = this.props

    return this.api
      .addPaymentToInvoice(client, invoice, form)
      .then((invoice) => {
        onPaymentAdded(invoice)
        this.setState({ isFormShowing: false })
      })
  }

  handleCancel = () => {
    this.setState({ isFormShowing: false })
  }

  render() {
    const { isFormShowing } = this.state
    const { invoice, client } = this.props

    const fields = {
      memo: text({
        required: true,
      }),
      amount: currency({
        min: 0.01,
        max: invoice.balanceDue,
      }),
      effective_at: date({
        label: 'Effective Date',
        required: true,
      }),
    }
    if (client.stripeCardId) {
      fields.shouldUseStripe = toggle({
        label: 'Pay by Card',
        defaultValue: true,
        memo: `Card on file: ${client.stripeCardBrand} x${client.stripeCardLast4}`,
      })
    }
    return (
      <React.Fragment>
        <Table.Row className="no-print">
          {!isFormShowing && (
            <React.Fragment>
              <Table.Cell colSpan={3} />
              <Table.Cell>
                <Button
                  size="small"
                  compact
                  primary
                  onClick={this.handleShowForm}
                >
                  <Icon name="plus" /> Create Payment
                </Button>
              </Table.Cell>
            </React.Fragment>
          )}
          {isFormShowing && (
            <React.Fragment>
              <Table.Cell colSpan={3}>
                <h4>Payment Entry</h4>
                <Form
                  submitButtonText={(form) =>
                    form.shouldUseStripe ? 'Charge Now' : 'Save Manual Payment'
                  }
                  fields={fields}
                  onCancel={this.handleCancel}
                  onSubmit={this.handleSubmit}
                />
              </Table.Cell>
            </React.Fragment>
          )}
        </Table.Row>
      </React.Fragment>
    )
  }
}

export { CreatePaymentLineItem }
