import React from 'react'
import _, { map, each } from 'lodash'
import { Dropdown, Grid, Table } from 'semantic-ui-react'
import { subscribe } from 'react-contextual'
import changeCase from 'change-case'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { DatePicker, ComponentBase, Async, createDateRange } from '~/Kit'
import { Currency } from '~/components/Currency'

const ReportLineItem = (props) => {
  const { item, routes, fieldRenderers } = props

  const cols = map(fieldRenderers, (render, k) =>
    k in fieldRenderers ? (
      <Table.Cell key={k}>{render(item)}</Table.Cell>
    ) : null,
  )

  return <Table.Row>{cols}</Table.Row>
}

const ReportHeader = (props) => {
  const { sums, fieldRenderers } = props
  const cols = []
  _.each(fieldRenderers, (render, k) => {
    cols.push(
      <Table.HeaderCell key={k}>{changeCase.title(k)}</Table.HeaderCell>,
    )
  })

  return (
    <React.Fragment>
      <Table.Row>{cols}</Table.Row>
      <Table.Row>
        {_.map(fieldRenderers, (render, k) => (
          <Table.HeaderCell key={k}>
            {k in sums ? <Currency value={sums[k]} /> : ''}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </React.Fragment>
  )
}

const PaymentsReport = (props) => {
  const { report, routes } = props
  if (report.length === 0) return 'No payments for this period.'
  const DATE_FORMAT = 'M/D/YY'

  const fieldRenderers = {
    effectiveAt: ({ effectiveAt }) =>
      moment(effectiveAt).tz('America/Boise').format(DATE_FORMAT),

    client: ({ client }) => (
      <Link
        to={routes.clients.edit.billing.invoicer({
          client: client.id,
        })}
      >
        {client.name}
      </Link>
    ),
    reps: ({ reps }) => map(reps, (r) => r.name).join(', '),

    memo: ({ memo, internalMemo }) => (
      <React.Fragment>
        {memo}
        <div>{internalMemo}</div>
      </React.Fragment>
    ),
    method: ({ internalMemo, txnId }) =>
      txnId && (
        <a
          href={`https://dashboard.stripe.com/payments/${txnId}`}
          target="_blank"
        >
          Stripe
        </a>
      ),
    amount: ({ amount }) => <Currency value={amount} />,
  }

  const sums = { amount: 0 }
  each(report, (item) =>
    each(sums, (junk, k) => {
      const v = item[k]
      sums[k] += v
    }),
  )
  return (
    <div style={{ overflow: 'auto' }}>
      <Table celled>
        <Table.Header>
          <ReportHeader sums={sums} fieldRenderers={fieldRenderers} />
        </Table.Header>
        <Table.Body>
          {_.map(report, (item, idx) => {
            return (
              <ReportLineItem
                key={idx}
                item={item}
                routes={routes}
                fieldRenderers={fieldRenderers}
              />
            )
          })}
        </Table.Body>
        <Table.Footer>
          <ReportHeader sums={sums} fieldRenderers={fieldRenderers} />
        </Table.Footer>
      </Table>
    </div>
  )
}

@subscribe('ioc')
class Payments extends ComponentBase {
  componentDidMount() {
    super.componentDidMount()
    const { start, end } = createDateRange('lastMonth')
    this.setState({
      report: this.api.getPaymentsReport(start, end, null),
      reps: this.api.getReps(),
      repFilterId: null,
      startDate: start,
      endDate: end,
    })
  }

  handleDateChange = (startDate, endDate) => {
    const { repFilterId } = this.state
    this.setState({
      startDate: startDate.startOf('day'),
      endDate: endDate.endOf('day'),
      report: this.api.getPaymentsReport(startDate, endDate, repFilterId),
    })
  }

  onRepChanged = (e, d) => {
    const { startDate, endDate } = this.state
    this.setState({
      repFilterId: d.value,
    })
    this.setState({
      report: this.api.getPaymentsReport(startDate, endDate, d.value),
    })
  }

  renderLoaded() {
    const { report, reps } = this.state
    const { routes } = this.props.ioc

    return (
      <Grid columns={1}>
        <Grid.Row>
          <Grid.Column>
            <DatePicker onChange={this.handleDateChange} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Async
              watch={reps}
              onLoaded={(reps) => (
                <Dropdown
                  placeholder="Rep Filter"
                  fluid
                  selection
                  clearable="true"
                  options={[
                    {
                      key: 'any',
                      text: 'All reps',
                      value: null,
                    },
                  ].concat(
                    _.map(reps, (r) => ({
                      key: r.id,
                      text: r.name,
                      value: r.id,
                    })),
                  )}
                  onChange={this.onRepChanged}
                />
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Async
              watch={report}
              onLoaded={(report) => (
                <PaymentsReport report={report} routes={routes} />
              )}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export { Payments }
