import React, { Component } from 'react'
import _ from 'lodash'
import { Form as FormBase } from '~/Kit'

const { div, text } = FormBase.Builders

const Stripe = (props) => {
  const {
    client: {
      stripeCustomerId,
      stripeCardId,
      stripeCustomerName,
      stripeCardBrand,
      stripeCardExpMonth,
      stripeCardExpYear,
      stripeCardLast4,
    },
  } = props
  const fields = {
    stripeCustomerId: text({
      defaultValue: stripeCustomerId,
      help: () => 'Found in Account Information in Stripe Dashboard',
    }),
    stripeCardId: text({
      defaultValue: stripeCardId,
      help: () => 'Found in Card ID in Customer Profile in Stripe Dashboard',
    }),
    stripeCustomerName: div({
      content: stripeCustomerName || (
        <span style={{ color: 'grey' }}>Provided by Stripe API</span>
      ),
    }),
    stripeCardBrand: div({
      content: stripeCardBrand || (
        <span style={{ color: 'grey' }}>Provided by Stripe API</span>
      ),
    }),
    stripeCardExpiration: div({
      content: (stripeCardExpMonth &&
        stripeCardExpYear &&
        `${stripeCardExpMonth}/${stripeCardExpYear}`) || (
        <span style={{ color: 'grey' }}>Provided by Stripe API</span>
      ),
    }),
    stripeCardLast4: div({
      content: (stripeCardLast4 && `x${stripeCardLast4}`) || (
        <span style={{ color: 'grey' }}>Provided by Stripe API</span>
      ),
    }),
  }

  return <FormBase {...props} fields={fields} />
}

export { Stripe }
