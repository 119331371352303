import _ from 'lodash'
import { ModelBase } from '../Kit'
import { Notification } from './Notification'

class Client extends ModelBase {
  static getMutators() {
    return _.extend(super.getMutators(), {
      notifications: (arr) => Notification.create(arr),
    })
  }
}

export { Client }
